<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" class="text-center">
        <v-img
          src="/images/logo-nodriza-negro.png"
          max-width="110px"
          class="ma-auto my-5"
        >
        </v-img>
        <h1 class="display-2 font-weight-bold my-5">ONESTOCK</h1>
        <v-hover v-slot:default="{ hover }" open-delay="200">
          <router-link
            class="v-btn v-btn--contained v-size--default v-application mr-5 mt-5"
            to="/clientes"
            ><v-btn
              :elevation="hover ? 0 : 10"
              rounded
              block
              width="170px"
              :color="hover ? 'infoDark':'info'"
              >Cliente</v-btn
            ></router-link
          >
        </v-hover>

        <v-hover v-slot:default="{ hover }" open-delay="200">
          <router-link
            class="v-btn v-btn--contained v-size--default v-application mr-5 mt-5"
            to="/admin"
          >
            <v-btn
              :elevation="hover ? 0 : 12"
              rounded
              block
              width="170px"
              :color="hover ? 'primaryDark':'primary' "
              >Administración</v-btn
            ></router-link
          >
        </v-hover>

        <v-hover v-slot:default="{ hover }" open-delay="200">
          <router-link
            class="v-btn v-btn--contained v-size--default v-application mt-5"
            to="/proveedor"
            ><v-btn
              :elevation="hover ? 0 : 12"
              rounded
              block
              width="170px"
              :color="hover ? 'secondaryDark':'secondary' "
              >Proveedor</v-btn
            ></router-link
          ></v-hover
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { EventBus } from "@/main.js";

export default {
  name: "Home",
  title: "Home - Onestock",
};
</script>
